import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMeasure } from '@uidotdev/usehooks';
import { useMemo } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryTooltip } from 'victory';
import { useIntervals } from 'Global/hooks/useGraphIntervals';
import theme from 'Global/theme';
import { formatCurrency, formatCurrencyYAxis } from 'Global/utils';
export default function CostsRevenueChart({ data, groups }) {
    const [ref, { width, height }] = useMeasure();
    const formatTick = (tick) => {
        return formatCurrencyYAxis(tick, false);
    };
    const domain = useMemo(() => {
        const domain = [0, 0];
        data.reduce((accum, item) => {
            const max = Math.max(Math.abs(item.income), Math.abs(item.spending));
            if (max < accum[0]) {
                accum[0] = max;
            }
            if (max > accum[1]) {
                accum[1] = max;
            }
            return accum;
        }, domain);
        return domain;
    }, [data]);
    const intervals = useIntervals(domain);
    return (_jsx("div", { ref: ref, style: { aspectRatio: 1.5 }, children: _jsxs(VictoryChart, { width: width ?? 0, height: height ?? 0, domainPadding: { x: 20, y: 20 }, padding: { left: 70, top: 20, right: 20, bottom: 50 }, children: [_jsx(VictoryAxis, { style: {
                        axis: { stroke: 'transparent' },
                        tickLabels: { fill: theme.colors.gray, fontSize: 12, padding: 5 },
                        axisLabel: { fontSize: 12 },
                    }, orientation: 'bottom', offsetY: 50 }), _jsx(VictoryAxis, { dependentAxis: true, style: {
                        axis: { stroke: 'transparent' },
                        grid: { stroke: '#EEEEEE', strokeDasharray: '2,2' },
                        tickLabels: { fill: theme.colors.gray, fontSize: 12, padding: 5 },
                        axisLabel: { fontSize: 12 },
                    }, tickFormat: formatTick, tickValues: intervals }), _jsx(VictoryGroup, { offset: 15, colorScale: 'qualitative', children: groups?.map((group, index) => {
                        return (_jsx(VictoryBar, { cornerRadius: { top: 5, bottom: 5 }, data: data, style: {
                                data: {
                                    fill: ({ datum }) => {
                                        const date = new Date(datum.date + '-01');
                                        return `${group.barColor ?? theme.colors.primary}${date.getTime() > Date.now() ? '33' : 'FF'}`;
                                    },
                                    width: 10,
                                },
                            }, x: group.x, y: group.y, labels: ({ datum }) => formatCurrency(index === 0 ? datum.income : datum.spending, false), labelComponent: _jsx(VictoryTooltip, { style: { fill: theme.colors.gray }, dy: -5 }) }, index));
                    }) })] }) }));
}
