import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationDe from './de.json';
const resources = {
    de: {
        translation: translationDe,
    },
};
i18n.use(initReactI18next).init({
    resources,
    lng: 'de',
    fallbackLng: 'de',
});
export default i18n;
