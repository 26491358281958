import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { t } from 'i18next';
import { parseAsString, useQueryState } from 'nuqs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, HorizontalMenu, InfoBox, SearchInput } from 'ui-components-web';
import FincheckLogo from 'Assets/Fincheck-logo.svg';
import UserChecked from 'Assets/User-checked.svg';
import { CustomEvents, StorageKeys } from 'Global/types';
import { getItemFromStorage, setItemInStorage, trackEvent } from 'Global/utils';
import ROUTES from 'Router/routes';
import { getOverview } from 'Service/api/finInsights';
import { InfoBoxesContainer, ListContainer, OverviewMenuContainer, OverviewNavContainer, SearchContainer, } from '../style';
import InvitationsTab from './InvitationsTab';
import UsersTab from './UsersTab';
var Tab;
(function (Tab) {
    Tab["Users"] = "users";
    Tab["Invitations"] = "invitations";
})(Tab || (Tab = {}));
const items = [
    {
        label: 'FinCheck Nutzer',
        key: Tab.Users,
    },
    {
        label: 'Einladungen',
        key: Tab.Invitations,
    },
];
function Overview() {
    const navigate = useNavigate();
    const vrsg_session = getItemFromStorage(StorageKeys.VRSG_SESSION);
    // const [searchFilter, setSearchFilter] = useState<string | undefined>()
    const [searchFilter, setSearchFilter] = useQueryState('filter', parseAsString.withDefault(''));
    const [selectedTab, setSelectedTab] = useState(Tab.Users);
    const [overviewValues, setOverviewValues] = useState({
        fincheck_users: 0,
        customers_ready_to_be_contacted: 0,
    });
    const handleNavigate = () => {
        trackEvent(CustomEvents.INVITE_PORTAL_BTN_CLICK);
        navigate(ROUTES.INVITE_PORTAL);
    };
    const handleSearch = (text) => {
        trackEvent(CustomEvents.SEARCH_COMPANY_IN_LIST_INPUT);
        setSearchFilter(text ?? '');
    };
    // Extract token from magic link redirect URL
    useEffect(() => {
        const s = window.location.search;
        if (s) {
            setItemInStorage(StorageKeys.VRSG_SESSION, s.split('s=')[1]);
        }
    }, [vrsg_session]);
    useEffect(() => {
        getOverview()
            .then((result) => {
            const { data } = result;
            setOverviewValues({
                fincheck_users: data.fincheck_users,
                customers_ready_to_be_contacted: data.customers_ready_to_be_contacted,
            });
        })
            .catch((error) => console.log({ error }));
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", { style: { paddingBottom: '5em' }, children: [_jsx("h3", { children: t('OverviewPage.Overview') }), _jsxs(InfoBoxesContainer, { children: [_jsx("div", { style: { flex: 0.33 }, children: _jsx(InfoBox, { count: overviewValues.fincheck_users, title: t('OverviewPage.FincheckUsers'), icon: FincheckLogo }) }), _jsx("div", { style: { flex: 0.33 }, children: _jsx(InfoBox, { count: overviewValues.customers_ready_to_be_contacted, title: t('OverviewPage.AwaitingCustomers'), icon: UserChecked }) })] })] }), _jsxs(ListContainer, { children: [_jsxs(OverviewNavContainer, { children: [_jsx(OverviewMenuContainer, { children: _jsx(HorizontalMenu, { items: items, callback: (key) => setSelectedTab(key) }) }), _jsxs(SearchContainer, { children: [_jsx(Button, { "data-test-id": 'invite-portal-button', text: t('OverviewPage.InviteCustomers'), type: 'primary', onClick: handleNavigate }), _jsx(SearchInput, { placeholder: t('FormsInputs.Search'), onSearch: (text) => {
                                            handleSearch(text);
                                        } })] })] }), selectedTab === Tab.Users && _jsx(UsersTab, { filter: searchFilter }), selectedTab === Tab.Invitations && _jsx(InvitationsTab, { filter: searchFilter })] })] }));
}
export default Overview;
