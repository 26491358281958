import finInsights from './api';
export const generateMagicLink = (email, callback) => loginWithEmail(email)
    .catch((error) => console.log({ error }))
    .finally(() => callback());
export const loginWithEmail = (email) => finInsights.post('login', { email });
export const getCustomers = (page, filter) => finInsights.get(`/customers?page=${page ?? 1}${filter ? `&search=${filter}` : ''}`);
export const updateCustomerStatus = (cutomer_id, status) => finInsights.put(`/customers/${cutomer_id}`, {
    status: status,
});
export const getOverview = () => finInsights.get('/overview');
export const getCustomerDetails = (sme_tenant_id, sme_identity_id) => finInsights.get(`/customers/${sme_tenant_id}/insights?identity_id=${sme_identity_id}`);
export const getRecommendations = (sme_tenant_id, sme_identity_id) => finInsights.get(`/customers/recommendations/${sme_tenant_id}?identity_id=${sme_identity_id}`);
export const inviteUser = (payload) => finInsights.post(`/invitations`, payload);
export const getPendingInvitations = () => finInsights.get(`/invitations`);
export const getCustomerEvents = (customer_id) => finInsights.get(`/customers/${customer_id}`);
export const addCustomerEvent = (customer_id, description) => finInsights.post(`/events`, {
    customer_id: customer_id,
    description: description,
});
export const deleteCustomerEvent = (id) => finInsights.delete(`/events/${id}`);
export const setProductFeedback = (sme_tenant_id, sme_identity_id, product_id, feedback) => finInsights.put(`/customers/recommendation_feedback/${sme_tenant_id}?identity_id=${sme_identity_id}`, {
    product_id,
    feedback,
});
