import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { useMemo } from 'react';
import { ChartIndicators, ColoredDotLabel, WhiteBlock } from 'ui-components-web';
import CostsRevenueChart from 'Components/CostsRevenueChart';
import theme from 'Global/theme';
import { formatCurrency, formatDate } from 'Global/utils';
import { IndicatorsWrapper } from './style';
function formatMonthYear(dateString) {
    const date = new Date(dateString);
    return `${t(`Months.${date.getMonth()}`)} ${date.getFullYear().toString().substring(2)}`;
}
function getAverages(items) {
    const past = items.filter((i) => !i.is_forecast);
    const future = items.filter((i) => i.is_forecast);
    const avgPast = past.reduce((accum, i) => {
        accum += i.totalAmount ? i.totalAmount / past.length : 0;
        return accum;
    }, 0);
    const avgFuture = future.reduce((accum, i) => {
        accum += i.totalAmount ? i.totalAmount / future.length : 0;
        return accum;
    }, 0);
    return [avgPast, avgFuture];
}
export default function CostsRevenueSection({ income, spending, status, lastUpdated }) {
    const data = useMemo(() => {
        const chartData = [];
        income?.forEach((item) => {
            chartData.push({
                isForecast: item.is_forecast,
                month: formatMonthYear(item.month),
                date: item.month,
                income: item.totalAmount && item.totalAmount < 0
                    ? item?.totalAmount * -1
                    : (item.totalAmount ?? 0),
                spending: 0,
            });
        });
        spending?.forEach((item) => {
            chartData.push({
                isForecast: item.is_forecast,
                month: formatMonthYear(item.month),
                date: item.month,
                income: 0,
                spending: item.totalAmount && item.totalAmount < 0
                    ? item?.totalAmount * -1
                    : (item.totalAmount ?? 0),
            });
        });
        return chartData.sort((a, b) => {
            if (a.date < b.date) {
                return -1;
            }
            if (a.date > b.date) {
                return 1;
            }
            return 0;
        });
    }, [income, spending]);
    const chartGroups = useMemo(() => {
        return [
            {
                x: 'month',
                y: 'income',
                barColor: theme.colors.lightBlue,
            },
            {
                x: 'month',
                y: 'spending',
                barColor: theme.colors.primary,
            },
        ];
    }, [theme]);
    const cashFlowAverageChangeAndTrend = useMemo(() => {
        const incomeAvg = getAverages(income);
        const spendingAvg = getAverages(spending);
        const avgPastChange = incomeAvg[0] - Math.abs(spendingAvg[0]);
        const avgFutureChange = incomeAvg[1] - Math.abs(spendingAvg[1]);
        const trend = (avgPastChange / avgFutureChange) * 100;
        return [avgPastChange, Math.floor(trend)];
    }, [income, spending]);
    return (_jsxs(WhiteBlock, { style: { paddingBottom: '5em', padding: '1em' }, children: [_jsx(IndicatorsWrapper, { children: _jsx(ChartIndicators, { title: `${t('CustomerDetails.Revenue')} vs. ${t('CustomerDetails.Costs')}`, amount: '⌀' + formatCurrency(cashFlowAverageChangeAndTrend[0]), lastUpdate: `${t('CustomerDetails.LastUpdate')} ${lastUpdated ? formatDate(lastUpdated) : ''}`, status: status, percentage: cashFlowAverageChangeAndTrend[1].toString(), text: `${cashFlowAverageChangeAndTrend[1].toString()}% ${t('CustomerDetails.InTheNextYear')}` }) }), _jsx(CostsRevenueChart, { data: data, groups: chartGroups }), _jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    gap: '2rem',
                    paddingLeft: 70,
                }, children: [_jsx(ColoredDotLabel, { fontSize: '15px', text: t('CustomerDetails.Revenue'), color: theme.colors.lightBlue }), _jsx(ColoredDotLabel, { fontSize: '15px', text: t('CustomerDetails.Costs'), color: theme.colors.primary })] })] }));
}
