import { useMemo } from 'react';
const levels = [
    1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000, 100000, 200000, 500000,
    1000000, 2000000, 5000000, 10000000, 20000000, 50000000, 100000000, 200000000, 500000000,
];
function getIntervals(min, max, level) {
    if (Math.abs(max % level) !== 0) {
        max = max - (max % level) + level;
    }
    if (Math.abs(min % level) !== 0) {
        min = min - (min % level) - level;
    }
    const intervals = [];
    for (let i = min; i <= max; i += level) {
        intervals.push(i);
    }
    return intervals;
}
export function useIntervals(domain) {
    const intervals = useMemo(() => {
        let intervals = [];
        for (let l = 0; l <= levels.length; l++) {
            intervals = getIntervals(domain[0], domain[1], levels[l]);
            if (intervals.length >= 4 && intervals.length <= 8) {
                break;
            }
        }
        return intervals;
    }, [domain]);
    return intervals;
}
