import styled from 'styled-components';
import { mediaQueries } from 'Global/styled';
export const LoginContainer = styled.div `
  background-color: ${({ theme }) => theme.colors.nightBlue};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const LoginBlock = styled.div `
  background-color: ${({ theme }) => theme.colors.white};
  width: 500px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 0.5em;
`;
export const ContentContainer = styled.div `
  padding: 5px 15px 0px 15px;
`;
export const OverviewNavContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  ${mediaQueries.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const OverviewMenuContainer = styled.div `
  flex-grow: 1;
`;
export const SearchContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
`;
export const InvitePortalContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const FormContainer = styled.div `
  width: 30em;
`;
export const InfoBoxesContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${mediaQueries.md} {
    flex-direction: row;
  }
`;
export const ListContainer = styled.div `
  padding-bottom: 5em;
  .ant-table-cell {
    vertical-align: top !important;
  }
`;
export const AlertContainer = styled.div `
  margin-top: 1.5em;
`;
export const LoginAlertContainer = styled.div `
  margin-top: 1.5em;
  width: 500px;
`;
export const BlockWrapper = styled.div `
  margin-top: 1rem;
  display: flex;
  flex-direction: column;

  gap: 1rem;

  ${({ $customStyles }) => $customStyles && { ...$customStyles }};

  ${mediaQueries.md} {
    flex-direction: row;
    align-items: center;
    justify-content: ${({ $isMultipleChildren }) => $isMultipleChildren ? 'space-between' : 'flex-end'};
    grid-column: ${({ $isMultipleChildren }) => $isMultipleChildren ? 'span 9 / span 9' : 'span 12 / span 12'};
  }
`;
export const ChartBlockWrapper = styled.div `
  flex: 1;
`;
export const PageContentWrapper = styled.div `
  padding-top: 1em;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 2rem;
`;
export const MainContentWrapper = styled.div `
  grid-column-start: 1;
  grid-column: span 12 / span 12;
  ${mediaQueries.md} {
    grid-column: ${({ $isMultipleChildren }) => $isMultipleChildren ? 'span 9 / span 9' : 'span 12 / span 12'};
  }
`;
export const RightColumnContentWrapper = styled.div `
  grid-column-start: 10;
  grid-column: span 12 / span 12;
  ${mediaQueries.md} {
    grid-column: span 3 / span 3;
  }
`;
export const PaginationContainer = styled.div `
  margin-top: 4rem;
`;
